<template>
<v-main>
  <LayoutEngineTest></LayoutEngineTest>
</v-main>
</template>

<script>
import RenderBox from "@/components/layoutengine/RenderBox.vue";
import LayoutEngineTest from "@/components/layoutengine/LayoutEngineTest.vue";

export default {
  name: "LayoutEngine",
    components: { LayoutEngineTest, RenderBox }
};
</script>

<style scoped>

</style>