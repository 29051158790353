<template>
    <div ref="renderBox" class="elevation-4">

    </div>
</template>

<script>

import mitt from "mitt";

window.mitt = window.mitt || new mitt();

export default {
    name: "RenderBox",
    data: () => ({

    }),
    mounted() {
        window.mitt.on("layout-engine.render", this.render);
    },
    methods() {

    },
    beforeDestroy() {
        window.mitt.off("customLayoutSelectItem-Kontaktlisten", this.selectionEvent);
        window.mitt.off("contact-list.refresh", this.refreshEvent);
    }
};
</script>

<style scoped>
div {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 427px;
    height: 240px;
    background-color: white;
}
</style>