<template>
<div>
    <RenderBox></RenderBox>
</div>
</template>

<script>
import RenderBox from "@/components/layoutengine/RenderBox.vue";

export default {
  name: "LayoutEngineTest",
    components: { RenderBox }
};
</script>

<style scoped>

</style>